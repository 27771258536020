<!--
 * @Author: zry
 * @Date: 2024-01-23 16:55:53
 * @LastEditors: zry
 * @LastEditTime: 2024-02-19 10:46:20
 * @Description: 部件/同楼层部件  历史火警报警/误报/维保折线图 按天统计
-->
<template>
  <div class="container">
    <div style="">
      <div style="display: flex;height: 30px;">
        <div class="vertical-bar" />
        <div style="display: flex; align-items: center; justify-content: center;">
          <span style="color: #fff; font-size: 18px;">{{ title }}</span>
        </div>
      </div>
      <div style="">
        <div
          v-if="lc === 'bj'"
          style="width: 100%; height: auto;"
        >
          <div
            class="wb-title"
            style="display: flex; align-items: center;"
          >
            <image
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            />
            <span
              v-if="groupBy==='week'"
              class="wb-title-span-1"
            >近7天高频报警时间: </span>
            <span
              v-if="groupBy==='mouth'"
              class="wb-title-span-1"
            >近30天高频报警时间: </span>
            <span
              v-for="key in gp"
              :key="key"
              class="wb-title-span-2"
            > {{ key }}</span>
          </div>
          <div
            class="wb-title"
            style="display: flex; align-items: center;margin-top: 20px;"
          >
            <image
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            />
            <span
              v-if="groupBy==='week'"
              class="wb-title-span-1"
            >近7天报警次数总数: </span>
            <span
              v-if="groupBy==='mouth'"
              class="wb-title-span-1"
            >近30天报警次数总数: </span>
            <span class="wb-title-span-2"> {{ totalCount }}</span>
          </div>
          <div
            class="wb-title"
            style="display: flex; align-items: center;margin-top: 20px;"
          >
            <image
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            />
            <span
              v-if="groupBy==='week'"
              class="wb-title-span-1"
            >近7天误报次数总数: </span>
            <span
              v-if="groupBy==='mouth'"
              class="wb-title-span-1"
            >近30天误报次数总数: </span>
            <span
              class="wb-title-span-2"
              style="color:#FF7E00;"
            > {{ wbCount }}</span>
          </div>
        </div>
        <div
          ref="lszshj"
          style="width: 800px; height: 280px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { lshjService } from './LshjService.js';
export default {
  name: 'LshjwbChart',
  props: {
    lc: {
      type: String,
      default: 'bj'
    },
    groupBy: {
      type: String,
      default: 'week'
    },
    ssdw: {
      type: String,
      default: ''
    },
    gzsbh: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      gp: null, // 高频报警时间段
      lshjwbChart: null,
      windowWidth: 0,
      windowHeight: 0,
      title: '近7天报警',
      xdata: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
      ydataWb: [120, 132, 101, 134, 90, 230, 210],
      ydataWbcs: [120, 132, 101, 134, 90, 230, 210],
      ydataZs: [820, 932, 901, 934, 1290, 1330, 1320],
      totalCount: 0,
      wbCount: 0
    };
  },
  methods: {
    queryRltoFx () {
      const _this = this;
      const params = {
        ssdw: _this.ssdw,
        gzsbh: _this.gzsbh,
        groupBy: _this.groupBy,
        gb: 'me'
      };
      lshjService.queryRltoFx(params).then(res => {
        if (res.data) {
          const data = res.data;
          // console.log('========queryRltoFx===========', data);
          _this.gp = data.gp;
        }
      });
    },
    queryHjwb () {
      const _this = this;
      const params = {
        ssdw: _this.ssdw,
        gzsbh: _this.gzsbh,
        lc: _this.lc,
        groupBy: _this.groupBy
      };
      if (_this.groupBy === 'week' && _this.lc === 'bj') {
        _this.title = '部件近7天报警';
      } else if (_this.groupBy === 'mouth' && _this.lc === 'bj') {
        _this.title = '部件近30天报警';
      } else if (_this.groupBy === 'week' && _this.lc === 'lc') {
        _this.title = '同楼层部件近7天报警';
      } else if (_this.groupBy === 'mouth' && _this.lc === 'lc') {
        _this.title = '同楼层部件近30天报警';
      }
      // console.log('===================', params);
      lshjService.queryHjwb(params).then(res => {
        if (res.data) {
          const data = res.data;
          console.log('========queryHjwb===========', data);
          _this.xdata = data.xdata;
          _this.ydataWb = data.ydataWb;
          _this.ydataZs = data.ydataZs;
          _this.ydataWbcs = data.ydataWbcs;
          _this.totalCount = data.totalCount;
          _this.wbCount = data.wbCount;
          _this.initEchart();
        }
      });
    },
    updateWindowSize () {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      console.log('windowWidth', this.windowWidth);
    },
    initEchart () {
      this.lshjwbChart = echarts.init(this.$refs.lszshj);
      const option = {
        // title: {
        //   text: this.title,
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: 16
        //   }
        // },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '30px',
          right: '20px',
          data: ['真实火警', '误报火警', '维保测试'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '10%', // 左边距
          right: '10%', // 右边距
          // top: '8%', // 上边距
          bottom: '5%', // 下边距
          width: 'auto', // 宽度
          height: '200px', // 高度
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          data: this.xdata
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          minInterval: 1
        },
        series: [
          {
            name: '真实火警',
            type: 'line',
            data: this.ydataZs,
            itemStyle: {
              normal: {
                color: '#fd6119'
              }
            }
          },
          {
            name: '误报火警',
            type: 'line',
            data: this.ydataWb,
            itemStyle: {
              normal: {
                color: '#00F8E2'
              }
            }
          },
          {
            name: '维保测试',
            type: 'line',
            data: this.ydataWbcs,
            itemStyle: {
              normal: {
                color: '#f7b851'
              }
            }
          }
        ]
      };
      this.lshjwbChart.setOption(option);
    },
    resizeChart () {
      if (this.lshjwbChart) {
        this.lshjwbChart.resize();
      }
    }
  },
  watch: {
    groupBy: {
      handler (val, oldVal) {
        const _this = this;
        console.log('groupBy', val);
        if (this.lshjwbChart) {
          _this.queryHjwb();
          _this.queryRltoFx();
        }
      },
      immediate: true
    }
  },
  created () {
  },
  mounted () {
    const _this = this;
    _this.queryHjwb();
    _this.queryRltoFx();
    window.addEventListener('resize', _this.resizeChart);
    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeChart);
    window.removeEventListener('resize', this.updateWindowSize);
  }
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
}
::v-deep .uni-tag {
    width: 200px;
}
.vertical-bar {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
  width: 5px;  /* 控制竖条的宽度 */
  height: 18px;  /* 控制竖条的高度 */
  background-color: #28DEFF;  /* 控制竖条的颜色 */
  border-radius: 2.5px;  /* 设置圆角 */
}
.tb-xx{
  height: 18px;
  width: 18px;
  background-image: url('/images/screen/xq/水位建筑.png');
  border-radius: 10%;
  background-repeat: no-repeat;  // 添加这一行
}
.wb-title{
    margin-left: 10%;
    margin-top: 5%;
    // margin-right: 10%;
}
.wb-title-span-1{
  // margin-left: '100px';
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
  margin-left: 20px;
}
.wb-title-span-2{
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 800;
  margin-left: 20px;
  color: #00CEFF;
}
</style>
