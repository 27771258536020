<template>
  <div class="container">
    <div class="left-v">
      <div
        class="left-v-1"
        style="display: flex;"
      >
        <!-- 动态替换部件图片 -->
        <div :class="computedClass" />
        <div>
          <div class="hj-title">
            <img
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            >
            <span class="hj-title-span-1">火警部件: </span>
            <span class="hj-title-span-2">{{ gzsbhmc }} </span>
          </div>
          <div class="hj-title-1">
            <img
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            >
            <span
              class="hj-title-span-1-1"
            >联网单位: </span>
            <span class="hj-title-span-2-1">{{ ssdwmc }} </span>
          </div>
          <div class="hj-title-1">
            <img
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            >
            <span class="hj-title-span-1-1">安装位置: </span>
            <span class="hj-title-span-2-1">{{ slxxInfo.azwz }}  </span>
          </div>
          <div class="hj-title-1">
            <img
              class="tb-xx"
              src="/images/screen/xq/水位建筑.png"
            >
            <span class="hj-title-span-1-1">建筑楼层: </span>
            <span class="hj-title-span-2-1">{{ slxxInfo.jzlc }}  </span>
          </div>
        </div>
      </div>
      <div class="left-v-2">
        <GanntChart
          :ssdw="ssdw"
          :gzsbh="gzsbh"
        />
      </div>
      <div class="left-v-3">
        <LshjwbChart
          :group-by="groupBy"
          :gzsbh="gzsbh"
          :ssdw="ssdw"
          lc="bj"
        />
      </div>
    </div>
    <div class="right-v">
      <div class="right-v-1">
        <div class="common-bg">
          <div style="display: flex;height: 30px;">
            <div class="vertical-bar" />
            <div style="display: flex; align-items: center; justify-content: center;">
              <span style="color: #fff; font-size: 18px;">热力分布</span>
              <image
                class="history-js"
                src="/images/screen/xq/history-js.svg"
                title="该部件或同楼层相同类型的部件（如：烟感部件，温感部件），
在7天内各个时间段的报警情况，反应部件的报警高峰期"
              />
            </div>
            <div style="display: flex; justify-content: flex-end;margin-left: auto;">
              <select
                v-model="selectedOption2"
                class="dropdown"
                style="margin-right: 20px;"
                @change="handleSelectChange2"
              >
                <option
                  v-for="option in options2"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <select
                v-model="selectedOption"
                class="dropdown"
                style="margin-right: 30%;"
                @change="handleSelectChange"
              >
                <option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div style="width: 100%;">
            <LshjChart
              :group-by="selectedOption2"
              :gzsbh="gzsbh"
              :ssdw="ssdw"
            />
          </div>
        </div>
      </div>
      <div class="right-v-2">
        <LshjwbChart
          :group-by="groupBy"
          :gzsbh="gzsbh"
          :ssdw="ssdw"
          lc="lc"
        />
      </div>
      <div class="right-v-3">
        <YdTopChart
          :ssdw="ssdw"
          :gzsbh="gzsbh"
          :group-by="groupBy"
          jzlc="slxxInfo.jzlc"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LshjChart from './LshjChart.vue';
import LshjwbChart from './LshjwbChart.vue';
import GanntChart from './ganntChart.vue';
import YdTopChart from './ydTopChart.vue';

export default {
  name: 'Detail',
  components: {
    LshjChart,
    LshjwbChart,
    GanntChart,
    YdTopChart
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    },
    layerid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedOption: 'week',
      options: [
        { value: 'week', text: '近7天' },
        { value: 'mouth', text: '近30天' }
      ],
      selectedOption2: 'me',
      options2: [
        { value: 'me', text: '该部件' },
        { value: 'all', text: '同楼层' }
      ],
      groupBy: 'week',
      ssdw: '', // 所属单位
      gzsbh: '', // 故障设备编号
      gzsbhmc: '', // 故障设备名称
      ssdwmc: '', // 所属单位名称
      slxxInfo: {
        azwz: '',
        bjmc: '',
        gzsbh: '',
        jzid: '',
        ssdw: '',
        ssdwmc: '',
        jzlc: '',
        sslx: ''
      },
      iframeWindow: {
        whidth: '1650px', height: '900px'
      }
    };
  },
  computed: {
    computedClass () {
      switch (this.slxxInfo.sslx) {
        case 'HZ001':
          return 'left-v-1-bg';
        case 'HZ002':
          return 'left-v-1-bg-wg';
        case 'FH001':
          return 'left-v-1-bg-fhm';
        default:
          return 'left-v-1-bg-common';
      }
    }
  },
  methods: {
    handleSelectChange () {
      const that = this;
      that.groupBy = that.selectedOption;
      console.log('在这里处理select元素的改变', that.selectedOption);
    },
    handleSelectChange2 () {
    }
  },
  mounted () {
    document.getElementById(this.layerid).style.zIndex = 10000;
  },
  created () {
    this.ssdw = this.info.slxxInfo.ssdw;
    this.gzsbh = this.info.slxxInfo.gzsbh;
    this.gzsbhmc = this.info.slxxInfo.bjmc;
    this.slxxInfo = this.info.slxxInfo;
    this.ssdwmc = this.info.slxxInfo.ssdwmc;
    this.iframeWindow = this.info.iframeWindow;
    console.log('this.ssdw', this.ssdw);
    console.log('this.gzsbh', this.gzsbh);
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.left-v{
  width: 50%;
  flex: 1;
  margin-right: 10px;
  // background-color: #84b18a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  // 添加这一行
}
.left-v-1{
  height: 170px;
  background-color: #08193f;
  margin: 20px 5px 0px 20px;
  background-image: url('/images/screen/xq/left-v-1-bg.svg');
  background-repeat: no-repeat;  // 添加这一行
}
.left-v-1-bg{
  margin: 48px;
  width: 64px;
  height: 64px;
  background-image:  url('/images/screen/xq/history-yg.svg');
  background-repeat: no-repeat;  // 添加这一行
}
.left-v-1-bg-wg{
  margin: 48px;
  width: 64px;
  height: 64px;
  background-image:  url('/images/screen/xq/history-wg.svg');
  background-repeat: no-repeat;  // 添加这一行
}
.left-v-1-bg-fhm{
  margin: 48px;
  width: 64px;
  height: 64px;
  background-image:  url('/images/screen/xq/history-fhm.svg');
  background-repeat: no-repeat;  // 添加这一行
}
.left-v-1-bg-common{
  margin: 48px;
  width: 64px;
  height: 64px;
  background-image:  url('/images/screen/xq/history-common.svg');
  background-repeat: no-repeat;  // 添加这一行
}
.left-v-2{
  height: 210px;
  background-color: #08193f;
  margin: 20px 5px 0px 20px;
}
.left-v-3{
  flex-grow: 1;
  background-color: #08193f;
  margin: 20px 5px 5px 20px;
}
.right-v{
  width: 50%;
  flex: 1;
  margin-right: 10px;
  // background-color: #84b18a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  // 添加这一行
}
.right-v-1{
  height: 280px;
  background-color: #08193f;
  margin: 20px 10px 0px 5px;
}
.right-v-2{
  flex-grow: 1;
  background-color: #08193f;
  margin: 20px 10px 0px 5px;
}
.right-v-3{
  height: 280px;
  background-color: #08193f;
  margin: 20px 10px 5px 5px;
}

.common-bg{
  // background-color: #08193f;
  // margin: 20px;
}
.tb-xx{
  height: 18px;
  width: 18px;
  background-image: url('/images/screen/xq/水位建筑.png');
  border-radius: 10%;
  background-repeat: no-repeat;  // 添加这一行
}
.hj-title{
  margin-top: 33px;
  height: 18px;
  display: flex;
  //justify-content: center;
  align-items: center;
}

.hj-title-1{
  display: flex;
  margin-top: 5px;
}
.hj-title-span-1{
  display: inline-block;
  color: #FFFFFF;
  margin-left: 10px;
  font-size: 14px;
  height: 18px;
  font-weight: 400;
}
.hj-title-span-1-1{
  margin-left: 10px;color: #fff;font-size: 14px;font-weight: 400;
}
.hj-title-span-2-1{
  margin-left: 10px;color: #00CEFF;font-size: 14px;font-weight: 400;
}
.hj-title-span-2{
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  height: 18px;
  font-weight: 400;
  color: #00CEFF;
}
.vertical-bar {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
  width: 5px;  /* 控制竖条的宽度 */
  height: 18px;  /* 控制竖条的高度 */
  background-color: #28DEFF;  /* 控制竖条的颜色 */
  border-radius: 2.5px;  /* 设置圆角 */
}
.dropdown {
  background: #08193F;
  color: #fff;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  // padding: 5px;
  text-align: center;
}
.dropdown::after {  /* Custom dropdown arrow */
  // content: '>';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;  /* Ignore clicks on the arrow */
}
.history-js{
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.history-js:hover {
  cursor: pointer;
}
</style>
