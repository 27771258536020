/*
 * @Author: cgh
 * @Date: 2023-03-03 11:22:06
 * @LastEditors: cgh
 * @LastEditTime: 2023-06-18 11:38:55
 * @Description: file content
 */
import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const warnService = {

  // 获取详情（单位与建筑）
  getDetail: (id) => { return axios.get(`${iffApi}/jkzx/getSlxx/dp/${id}`); },

  // 获取火灾联动信息
  getDynamicData: (id) => { return axios.get(`${iffApi}/jkzx/getSlxxDtxx/${id}`); },

  // 通过火灾信息的所属单位获取建筑物列表信息
  getJzwBySsdw: (slId) => { return axios.get(`${iffApi}/jzw/getJzwBySlId/${slId}`); },

  // 获取CAD 图表信息
  getCadList: (id) => { return axios.get(`${iffApi}/jzw/getJzwCad/${id}`); },
  // 控制器开关
  change: (params) => { return axios.put(`${iffApi}/kzqgl/change`, params); },

  queryKzqList: (params) => { return axios.get(`${iffApi}/kzqgl`, { params: params }); }

};
