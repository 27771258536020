/*
 * @Author: cgh
 * @Date: 2022-08-01 10:49:32
 * @LastEditors: zxf
 * @LastEditTime: 2023-02-16 11:54:47
 * @Description: file content
 */

import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const videoService = {
  // 根据部件信息ID获取该回路下的设备信息
  listSbByBjxxId: (bjxxId) => { return axios.get(`${iffApi}/video/listSbByBjxxId/${bjxxId}`); },
  // 判断是否存在该编号设备
  checkSbExist: (params) => { return axios.get(`${iffApi}/video/checkSbExist`, { params: params }); },

  getVideoHost: () => { return axios.get(`${iffApi}/video/getVideoHost`); }

};
