import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const cadService = {
  queryCadBjxx: (params) => { return axios.get(`${iffApi}/jzw/cad/bjxx`, { params: params }); },

  // 新增
  saveCadBjxx: (params) => { return axios.post(`${iffApi}/jzw/cad/bjxx`, params); },

  // 修改
  updateCadBjxx: (id, params) => { return axios.put(`${iffApi}/jzw/cad/bjxx/${id}`, params); },

  // 删除
  deleteCadBjxx: (id) => { return axios.delete(`${iffApi}/jzw/cad/bjxx/${id}`); }
};
