<!--
 * @Author: zry
 * @Date: 2024-01-26 14:13:59
 * @LastEditors: zry
 * @LastEditTime: 2024-02-19 15:09:51
 * @Description: file content
-->
<template>
  <div class="container">
    <div style="">
      <div style="display: flex;height: 30px;">
        <div class="vertical-bar" />
        <div style="display: flex; align-items: center; justify-content: center;">
          <span style="color: #fff; font-size: 18px;">部件近3天报警</span>
          <image
            class="history-js"
            src="/images/screen/xq/history-js.svg"
            title="该部件在3天内火警报警持续情况，
反应部件火警持续时间以及正常时间"
          />
        </div>
      </div>
      <div
        ref="bjzt"
        style="width: 800px; height:calc(100% - 30px);"
      />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { lshjService } from './LshjService.js';
export default {
  name: 'GanntChart',
  props: {
    ssdw: {
      type: String,
      default: ''
    },
    gzsbh: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      bjztChart: null,
      totalStart: new Date('2021-03-01 00:00:00').getTime(),
      totalEnd: new Date('2021-03-08 00:00:00').getTime(),
      jsonData:
      [
        {
          category: '正常',
          series: '正常',
          startTime: '2021-03-01 00:00:00',
          endTime: '2021-03-01 12:00:00'
        },
        {
          category: '火警',
          series: '火警',
          startTime: '2021-03-01 12:00:00',
          endTime: '2021-03-01 14:00:00'
        },
        {
          category: '正常',
          series: '正常',
          startTime: '2021-03-01 14:00:00',
          endTime: '2021-03-02 00:00:00'
        },
        {
          category: '正常',
          series: '正常',
          startTime: '2021-03-02 00:00:00',
          endTime: '2021-03-03 00:00:00'
        }
      ]
    };
  },
  methods: {
    queryBjbjxxs () {
      const _this = this;
      const params = {
        ssdw: _this.ssdw,
        gzsbh: _this.gzsbh
      };
      console.log('queryBjbjxxs.params', params);
      lshjService.queryBjbjxxs(params).then(res => {
        if (res.data) {
          const data = res.data;
          console.log('queryBjbjxxs', data);
          _this.jsonData = data.data;
          _this.totalStart = new Date(data.totalStart).getTime();
          _this.totalEnd = new Date(data.totalEnd).getTime();
          _this.initEchart();
        }
      });
    },
    initEchart () {
      const _this = this;
      this.bjztChart = echarts.init(this.$refs.bjzt);
      const series = ['正常', '火警'];
      const startTimeAll = _this.totalStart;
      const endTimeAll = _this.totalEnd;
      const categories = ['正常', '火警'];
      const seriesValue = [];
      const jsonData = _this.jsonData;
      // Generate mock data
      echarts.util.each(categories, function (category, index) {
        const cgory = categories[index];
        series.forEach((itemS, indexS) => {
          jsonData.forEach((itemj, indexj) => {
            if (itemj.category === cgory) {
              const data = [];
              const startTime = new Date(itemj.startTime).getTime();
              const endTime = new Date(itemj.endTime).getTime();
              const duration = endTime - startTime;
              if (itemj.series === itemS) {
                data.push({
                  name: itemj.series,
                  value: [index, startTime, endTime, duration],
                  itemStyle: {
                    normal: {
                      shadowBlur: 10, // 阴影模糊大小
                      shadowColor: 'rgba(0, 0, 0, 1)', // 阴影颜色
                      shadowOffsetX: 5, // 阴影在 X 轴的偏移量
                      shadowOffsetY: 5, // 阴影在 Y 轴的偏移量
                      color: itemS === '正常' ? '#00f8e2' : '#FF7E00' // 根据系列名称设置颜色
                    }
                  }
                });
                seriesValue.push({
                  name: itemS,
                  type: 'custom',
                  renderItem: _this.renderItem,
                  itemStyle: {
                    normal: {
                      opacity: 0.8
                    }
                  },
                  encode: {
                    x: [1, 2],
                    y: 0
                  },
                  data: data
                });
              }
            }
          });
        });
      });
      const option = {
        legend: {
          type: 'scroll',
          data: ['正常', '报警'],
          show: false,
          orient: 'vertical',
          align: 'auto',
          top: '8%',
          right: '1%',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          confine: true,
          formatter: function (params) {
            let duration = Math.floor(params.value[3] / 1000 / 60 / 60);
            if (duration === 0) {
              duration = Math.floor(params.value[3] / 1000 / 60);
              if (duration === 0) {
                duration = Math.floor(params.value[3] / 1000) + ' 秒';
              } else {
                duration += ' 分钟';
              }
            } else {
              duration += ' 小时';
            }
            return params.marker + params.name + ': ' + duration;
          }
        },
        // title: {
        //   text: '部件近3天报警',
        //   left: 'left',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: 16
        //   }
        // },
        dataZoom: [{
          type: 'slider',
          filterMode: 'weakFilter',
          showDataShadow: true,
          bottom: 20,
          height: 0,
          borderColor: 'transparent',
          backgroundColor: '#e2e2e2',
          handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
          handleSize: 12,
          handleStyle: {
            shadowBlur: 6,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: '#fff'
          },
          labelFormatter: ''
        }, {
          type: 'inside',
          filterMode: 'weakFilter'
        }],
        grid: {
          left: '10%', // 左边距
          right: '10%', // 右边距
          top: '5%', // 上边距
          bottom: '30%', // 下边距
          width: 'auto', // 宽度
          height: 'auto' // 高度
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#ffffff' // 将X轴线颜色设置为白色
              }
            },
            min: startTimeAll,
            max: endTimeAll,
            interval: 3600 * 24 * 1000,
            axisLabel: {
              show: true,
              formatter: function (val) {
                const dateTime = new Date(val);
                return _this.getXDate(dateTime);
              }
            }
          }
        ],
        yAxis: {
          data: categories,
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: function (value) {
              if (value === '正常') {
                return '{normal|' + value + '}';
              } else if (value === '火警') {
                return '{alarm|' + value + '}';
              }
            },
            rich: {
              normal: {
                color: '#00f8e2' // 将"正常"标签颜色设置为绿色
              },
              alarm: {
                color: '#FF7E00' // 将"火警"标签颜色设置为红色
              }
            }
          },
          axisLine: {
            lineStyle: {
              color: '#ffffff' // 将X轴线颜色设置为白色
            }
          }
        },
        series: seriesValue
      };
      this.bjztChart.setOption(option);
    },
    getXDate (time) {
      const year = time.getFullYear();
      const month = parseInt(time.getMonth()) + 1;
      const day = time.getDate();
      //   const hour = time.getHours();
      //   const minutes = time.getMinutes();
      //   const seconds = time.getSeconds();
      //   return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
      return year + '-' + month + '-' + day;
    },
    renderItem (params, api) {
      const categoryIndex = api.value(0);
      const start = api.coord([api.value(1), categoryIndex]);
      const end = api.coord([api.value(2), categoryIndex]);
      const height = api.size([0, 1])[1] * 0.6;

      const rectShape = echarts.graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height
        }
      );

      return (
        rectShape && {
          type: 'rect',
          shape: rectShape,
          style: api.style()
        }
      );
    },
    resizeChart () {
      if (this.bjztChart) {
        this.bjztChart.resize();
      }
    }
  },
  created () {
  },
  mounted () {
    this.queryBjbjxxs();
    this.resizeChart();
  }

};
</script>

<style lang="scss" scoped>

// ::v-deep .uni-section__title {
//   color: none;
// }
// ::v-deep .uni-section__content-title {
//   color: #fff;
//   font-size: 18px;
// }
// ::v-deep .uni-section-header__decoration.line{
//   height: 18px;
// }
// ::v-deep .uni-section-header__decoration{
//   background-color: #28DEFF;
// }

.vertical-bar {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
  width: 5px;  /* 控制竖条的宽度 */
  height: 18px;  /* 控制竖条的高度 */
  background-color: #28DEFF;  /* 控制竖条的颜色 */
  border-radius: 2.5px;  /* 设置圆角 */
}
.history-js{
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.history-js:hover {
  cursor: pointer;
}
</style>
