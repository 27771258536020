<template>
  <div class="u-container">
    <div class="u-left" />
    <div class="u-text">
      {{ innerText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenTitle',
  props: {
    innerText: {
      type: String,
      default: '智慧机器人平台'
    }
  }
};
</script>

<style scoped lang="scss">
.u-container{
  display: flex;
  #align-items: center;
  #margin-bottom: 20px;
}
.u-left{
  width: 4px;
  height: 16.96px;
  background: #28DEFF;
  border-radius: 2px;
  margin-right: 10px;
}

.u-text{
  font-family: AlibabaPuHuiTi_2_85_Bold;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-bottom: 16px;
}

</style>
