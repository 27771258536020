<template>
  <!-- 火灾视频顶上一栏 -->
  <div class="u-container">
    <div>
      <img
        class="u-img"
        src="/monitor/images/hj/警告标志.svg"
      >
      <span class="u-text">{{ innerText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenTitle',
  props: {
    innerText: {
      type: String,
      default: '智慧机器人平台'
    }
  }
};
</script>

<style scoped lang="scss">
.u-container{
  height: 47px;
  background-image: linear-gradient(270deg, #962d0000 0%, #962d008a 54%, #962d0000 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.u-container > div {
  display: flex;
  align-items: center; // This will align items vertically in the center
  width: 75%;
  position: absolute;
  left: 15px;
  justify-content: center;
}
.u-text{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
}
.u-img{
  width: 24px;
  height: 23px;
  margin-right: 10px;
}

</style>
