<template>
  <!-- 火灾页面 消防设施 -->
  <div class="xfss-container">
    <div
      v-for="item in bjztPageList"
      :key="item.id"
      class="xfss-container-item"
    >
      <!-- 此组件调整成会传入部件 和 联动信息（有bjmlmc），根据是否有部件命令名称来进行区分 -->
      <div
        v-if="item.bjmlmc"
        class="xfss-container-item-zt"
      >
        <span class="xfss-container-item-zt-zc">
          联动
        </span>
      </div>
      <div
        v-if="item.bjmlmc"
        class="xfss-container-item-text"
      >
        {{ item.azwz }}{{ item.azwz?" - ":"" }}{{ item.bjmlmc }}
        <!-- 将采集时间转换为yyyy-mm-dd hh24:miss 格式-->

        <div style="">
          {{ ' ' }} {{ item.cjsj | date('MM-dd HH:mm:ss') }}
        </div>
      </div>

      <div
        v-if="!item.bjmlmc"
        class="xfss-container-item-zt"
      >
        <span :class="item.bjzt === '01' ? 'xfss-container-item-zt-zc': 'xfss-container-item-zt-yc'">
          {{ item.bjzt === '01' ? '正常' : item.bjzt === '10' ? '报警' : item.bjzt === '20'? '故障': '屏蔽' }}
        </span>
      </div>
      <div
        v-if="!item.bjmlmc"
        class="xfss-container-item-text"
      >
        {{ item.bjmc }}({{ item.azwz }}) {{ item.sz }} {{ item.dw }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'XfssTJ',
  components: {

  },
  props: {
    bjztPageList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
    };
  },
  methods: {

  },
  mounted () {
  },
  beforeDestroy () {
  }
};
</script>

<style scoped lang="scss">
.xfss-container{
  max-height: 90%;
  overflow-y: auto;
}

.xfss-container::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.xfss-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.xfss-container:hover::-webkit-scrollbar-thumb {
  height: 20px;
  background: #09369A;
}

.xfss-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.xfss-container-item{
  height: 36px;
  // background: linear-gradient(270deg, rgba(32, 45, 85, 0.01) 0%, rgba(44, 91, 148, 0.32) 100%);

  background: url('/monitor/images/hj/消防设施_矩形.svg') no-repeat center center;
  background-size: contain; // 或者选择其他的适应方式，如cover

  display: flex;
  align-items: center;
  margin-top: 8px;
}

.xfss-container-item-zt{
  width: 44px;
  height: 22px;
  background: #00d4f81a;
  box-shadow: inset 0 0 3px 0 #00d4f880;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
  margin-left: 15px;

}
.xfss-container-item-zt-zc{
  box-shadow: inset 0 0 3px 0 #00d4f880;
  color: #00F8E2;
}

.xfss-container-item-zt-yc{
  box-shadow: inset 0 0 3px 0 rgba(248, 0, 103, 0.5);
  color: #c22e2e;
}

.xfss-container-item-text{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;

  display: flex;
  align-items: center;
}

</style>
