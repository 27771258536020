<!--
 * @Author: zxf
 * @Date: 2022-10-14 11:17:50
 * @LastEditors: cgh
 * @LastEditTime: 2023-05-09 15:46:43
 * @Description: file content
-->
<!-- 火灾报警接入的视频展示 -->
<template>
  <div class="dev-c-video videos-container">
    <div
      v-if="spUrlList.length === 0"
      class="firewarn_movie"
    >
      <img
        style="width: 100%; height: 380px"
        src="/monitor/images/hj/hz_mr.png"
      >
      <img
        src="/monitor/images/hj/喇叭.svg"
        style="position: absolute;right:120px;top:390px"
      >
      <img
        src="/monitor/images/hj/扩大.svg"
        style="position: absolute;right:60px;top:390px"
      >
    </div>
    <template v-if="spUrlList.length === 1">
      <videoHls
        :video-url="spUrlList[0]"
        class="video-player"
      />
    </template>
    <template v-if="spUrlList.length > 1 ">
      <div
        v-for="(url, index) in spUrlList"
        :key="index"
        class="video-player video-player-multiple"
      >
        <videoHls :video-url="url" />
      </div>
    </template>
  </div>
</template>

<script>
import { videoService } from './video.service';
import config from '@/config';
import videoHls from '@/features/video/video-hls.vue';

export default {
  name: 'RunViedo',
  components: {
    videoHls
  },
  props: {
    bjxxId: {
      type: String,
      required: false,
      default: function () {
        return '';
      }
    }
  },
  data () {
    return {
      sbList: [],
      host: null,
      spUrlList: [],
      vimage: '/assets/video.png' // 视频封面地址
    };
  },
  methods: {
    // 根据部件信息ID获取该部件对应的回路
    listSbxxByBjxxId (bjxxId) {
      if (bjxxId) {
        const _this = this;
        const hasToken = this.$HMCookies.get(config.localTokenName) || false;
        videoService.listSbByBjxxId(bjxxId).then(res => {
          _this.sbList = res.data;
          // 遍历设备list，拼接视频地址
          _this.spUrlList = [];
          _this.sbList.forEach(item => {
            const spurl = item.videoUrl + '?time=' + new Date().getTime() + '&token=' + hasToken;
            _this.spUrlList.push(spurl);
          });
        });
      }
    }
  },
  watch: {
    bjxxId (val) {
      this.listSbxxByBjxxId(val);
    }
  },
  mounted () {
    this.listSbxxByBjxxId(this.bjxxId);
  }
};
</script>
<style lang="scss">

.videos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin: 0 auto;
  max-height: 400px; /* 设置最大高度为450px */
  overflow-y: auto; /* 当内容超出时显示滚动条 */
}

.video-player {
  width: 100%;
  height: 100%;
}

.video-player-multiple {
  width: calc(48% - 5px); // 减去间隙的一半确保两个视频并排
  height: 190px
}
.videos-container::-webkit-scrollbar {
  width: 4px;
  background: #09369A;
}
.videos-container::-webkit-scrollbar-thumb {
  background: #09369A;
}

.videos-container::-webkit-scrollbar-track {
  background: #08193f; /* 替换为您希望的背景颜色 */
}
</style>
