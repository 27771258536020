<template>
  <!-- 火灾页面 火灾信息 -->
  <div class="fire-container">
    <div class="fire-info">
      <div class="fire-line">
        <span class="fire-info-dot" />
        <p>火灾地点:  {{ baseInfo.hzdd }}</p>
      </div>
      <div class="fire-line">
        <span class="fire-info-dot" />
        <p>部件名称:  {{ baseInfo.bjmc }}</p>
      </div>
      <div class="fire-line">
        <span class="fire-info-dot" />
        <p>安装位置:  {{ baseInfo.azwz }}</p>
      </div>
      <div class="fire-line">
        <span class="fire-info-dot" />
        <p>发现时间:  {{ baseInfo.fxsj | date('yyyy-MM-dd HH:mm:ss') }}</p>
      </div>
      <div class="fire-line">
        <span class="fire-info-dot" />
        <p>消防负责人:  {{ baseInfo.xffzr }}</p>
      </div>
      <div class="fire-line">
        <span class="fire-info-dot" />
        <p>值班室电话:  {{ baseInfo.zbdh }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HzxxTJNoVideo',
  components: {

  },
  props: {
    baseInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {

    };
  },
  methods: {

  },
  mounted () {
  },
  beforeDestroy () {
  }
};
</script>

<style scoped lang="scss">
.fire-container{
  background-image: linear-gradient(180deg, #321414cc 0%, #19120fad 100%);
  width: 483px;
  box-sizing: border-box;
}

.fire-title{
  width: 283px;
  height: 30px;
  background-image: linear-gradient(270deg, #962d0000 0%, #962d008a 100%);
}
.fire-title-text{
  font-family: AlibabaPuHuiTi_2_85_Bold;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  padding-left: 10px;

  display: grid;
  align-items: center; /* 这是使内容垂直居中的关键 */
  height: 100%; /* 或你想要的任何高度 */

}
.fire-info-dot{
  width: 6px;
  height: 6px;
  background: #A42414;
  border-radius: 4px;
  margin-right: 8px; // Added some right margin for spacing
}
.fire-info {
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  padding-top: 10px;
  padding-bottom: 5px;
}
.fire-line{
  padding-left: 10px;
  height: 27px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
}
</style>
