/*
 * @Author: zry
 * @Date: 2023-01-05 11:07:00
 * @LastEditors: zry
 * @LastEditTime: 2024-02-19 15:21:48
 * @Description: file content
 */
import axios from 'axios';
import config from '@/config';

const { iffApi } = config.ctx;

export const lshjService = {
  queryHzxx: (bjxxId) => { return axios.get(`${iffApi}/bjxx/zt/gethzxx/${bjxxId}`); },
  queryHjwb: (params) => { return axios.get(`${iffApi}/bjxx/zt/getbjls`, { params: params }); },
  queryTop5: (params) => { return axios.get(`${iffApi}/bjxx/zt/getbjls/top5`, { params: params }); },
  queryBjbjxxs: (params) => { return axios.get(`${iffApi}/bjxx/zt/getbjls/queryBjbjxxs`, { params: params }); },
  queryRl: (params) => { return axios.get(`${iffApi}/bjxx/zt/getbjls/queryRl`, { params: params }); },
  queryRltoFx: (params) => { return axios.get(`${iffApi}/bjxx/zt/getbjls/queryRltoFx`, { params: params }); }
};
