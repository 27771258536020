<template>
  <div class="container">
    <div style="">
      <div style="display: flex;height: 30px;">
        <div class="vertical-bar" />
        <div style="display: flex; align-items: center; justify-content: center;">
          <span style="color: #fff; font-size: 18px;">同楼层部件{{ headerTitle }}报警Top5</span>
          <image
            class="history-js"
            src="/images/screen/xq/history-js.svg"
            title="同楼层相同类型的部件（如：烟感部件，温感部件），
在7天或30天内报警次数排行榜。
反应高频率报警部件统计情况"
          />
        </div>
      </div>
      <div
        ref="ydTop"
        style="width: 800px; height: 200px;"
      />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { lshjService } from './LshjService.js';
export default {
  name: 'YdTopChart',
  props: {
    ssdw: {
      type: String,
      default: ''
    },
    gzsbh: {
      type: String,
      default: ''
    },
    jzlc: {
      type: String,
      default: ''
    },
    groupBy: {
      type: String,
      default: 'week'
    }
  },
  data () {
    return {
      ydTop: null,
      headerTitle: '近七日',
      topData: [
        { name: '烟感1', value: 580 },
        { name: '烟感2', value: 536 },
        { name: '烟感3', value: 500 },
        { name: '烟感4', value: 350 },
        { name: '烟感5', value: 300 }
      ]

    };
  },
  methods: {
    queryTop5 () {
      const _this = this;
      const params = {
        ssdw: _this.ssdw,
        gzsbh: _this.gzsbh,
        jzlc: _this.jzlc,
        groupBy: _this.groupBy
      };
      lshjService.queryTop5(params).then(res => {
        if (res.data) {
          const data = res.data;
          console.log('data', data);
          _this.topData = data.data;
          _this.initEchart();
        }
      });
    },
    initEchart () {
      const _this = this;
      this.ydTop = echarts.init(this.$refs.ydTop);
      const colorList = ['#FF4B01', '#FF7B00', '#FFE823', '#2379FF', '#FFD37A', '#FFD37A'];
      const result = _this.topData;
      const option = {
        color: colorList,
        // backgroundColor: '#000416',
        // title: {
        //   text: '同楼层部件' + _this.headerTitle + '报警Top5',
        //   left: 'left',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: 16
        //   }
        // },
        tooltip: {
          show: true,
          trigger: 'item',
          padding: [8, 15],
          backgroundColor: 'rgba(12, 51, 115,0.8)',
          borderColor: 'rgba(3, 11, 44, 0.5)',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: '100px',
          width: 'auto',
          height: 'auto',
          // right: '16%'
          top: '5px',
          bottom: '0px'
        //   bottom: '8%'
        },
        xAxis: [
          {
            splitLine: {
              show: false
            },
            type: 'value',
            show: false
          }
        ],
        yAxis: [
          {
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            type: 'category',
            axisTick: {
              show: false
            },
            inverse: true,
            data: result.map((item) => item.name),
            axisLabel: {
              color: '#fff',
              fontSize: 14,
              margin: 10,
              formatter: (name, index) => {
                const id = index + 1;
                if (id < 5) {
                  return `{icon${id}|${id}}`;
                } else {
                  return `{count|${id}}`;
                }
              },
              rich: {
                icon1: {
                  width: 20,
                  height: 15,
                  padding: [5, 0, 2, 0],
                  align: 'center',
                  backgroundColor: '#FF4B01',
                  color: '#ffffff',
                  fontSize: 16
                },
                icon2: {
                  width: 20,
                  height: 15,
                  padding: [5, 0, 2, 0],
                  align: 'center',
                  backgroundColor: '#FF7B00',
                  color: '#ffffff',
                  fontSize: 16
                },
                icon3: {
                  width: 20,
                  height: 15,
                  padding: [5, 0, 2, 0],
                  align: 'center',
                  backgroundColor: '#FFD000',
                  color: '#ffffff',
                  fontSize: 16
                },
                icon4: {
                  width: 20,
                  height: 15,
                  padding: [5, 0, 2, 0],
                  align: 'center',
                  backgroundColor: '#2379FF',
                  color: '#ffffff',
                  fontSize: 16
                },
                count: {
                  width: 20,
                  height: 15,
                  padding: [5, 0, 2, 0],
                  align: 'center',
                  backgroundColor: '#FFD37A',
                  color: '#ffffff',
                  fontSize: 16
                }
              }
            }
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 12
              },
              formatter: function (value) {
                return value + '次';
              }
            },
            data: result.map((item) => item.value)
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: 10, // 柱子宽度
            MaxSize: 0,
            showBackground: true,
            barBorderRadius: [30, 0, 0, 30],
            backgroundStyle: {
              color: '#194B72'
            },
            label: {
              show: true,
              offset: [10, -16],
              color: '#fff',
              fontWeight: 500,
              position: 'left',
              align: 'left',
              formatter: function (params) {
                console.log(params);
                return params.data.name;
              }
            },
            data: result.map((item, index) => {
              return {
                name: item.name,
                value: item.value,
                itemStyle: {
                  barBorderRadius: [30, 0, 0, 30],
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#0F1F45'
                      },
                      {
                        offset: 1,
                        color: colorList[index]
                      }
                    ]
                  }
                }
              };
            })
          },
          {
            name: '外圆',
            type: 'scatter',
            emphasis: {
              scale: false
            },
            symbol: 'rect',
            symbolSize: [3, 12], // 进度条白点
            itemStyle: {
              barBorderRadius: [30, 0, 0, 30],
              color: '#FFF',
              shadowColor: 'rgba(255, 255, 255, 0.5)',
              shadowBlur: 5,
              borderWidth: 1,
              opacity: 1
            },
            z: 2,
            data: result,
            animationDelay: 500
          }
        ]
      };

      this.ydTop.setOption(option);
    },
    resizeChart () {
      if (this.ydTop) {
        this.ydTop.resize();
      }
    }
  },
  watch: {
    groupBy: {
      handler (val, oldVal) {
        const _this = this;
        _this.headerTitle = val === 'week' ? '近7天' : '近30天';
        if (_this.ydTop) {
          _this.queryTop5();
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.queryTop5();
  }
};
</script>

<style>
.container {
}
.vertical-bar {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
  width: 5px;  /* 控制竖条的宽度 */
  height: 18px;  /* 控制竖条的高度 */
  background-color: #28DEFF;  /* 控制竖条的颜色 */
  border-radius: 2.5px;  /* 设置圆角 */
}
.history-js{
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.history-js:hover {
  cursor: pointer;
}
</style>
