<template>
  <!-- 多火警列表 消防设施 -->
  <div class="hjlb-container">
    <div
      v-for="(item, index) in hjxxList"
      :key="item.id"
      class="hjlb-container-item"
      :class="{'hjlb-container-item_bgxz': item.id === slxxId }"
      @click="handlechangeHzSeleteId(item)"
    >
      <div class="hjlb-container-item-text">
        <div v-show="item.id ===slxxId">
          <img
            src="/monitor/images/hj/选择框_选中.svg"
            class="hjlb-container-item-img"
          >
        </div>
        <div v-show="item.id !==slxxId">
          <img
            src="/monitor/images/hj/选择框_未选中.svg"
            class="hjlb-container-item-img"
          >
        </div>
        {{ item.hzdd }}{{ item.slxxMxList && item.slxxMxList.length > 0 && item.slxxMxList[0].fhfqMc
          ? '（' + item.slxxMxList[0].fhfqMc + '）' : '' }}
        <div>
          <img
            v-show="index ===0"
            src="/monitor/images/hj/首警.svg"
            class="hjlb-container-item-shoujing"
          >
        </div>
      </div>

      <!-- <div v-show="item.slxxMxList && item.slxxMxList.length > 0">
        <div
          class="hjlb-sb-tj"
        >
          <div
            v-for="(bjsj,index2) in bjsblxTj"
            :key="index2"
          >
            <span
              v-if="bjsj.num > 0"
            >{{ bjsj.num }}个
              <span style="color:#FF5722;margin:0 5px">{{ bjsj.sblxmc }},</span>
            </span>
          </div>
        </div>

        <div
          v-for="(sbitem,index3) in item.slxxMxList"
          :key="index3"
        >
          <div

            class="hjlb-container-item-sj"
          >
            {{ sbitem.bjbw }}
            {{ item.scbjsj | date('yyyy-MM-dd HH:mm:ss') }}
          </div>
        </div>
      </div> -->

      <div
        v-if="showHjVideo"
        class="hjlb-container-item-sj"
      >
        {{ item.bjmc.substring(0,item.bjmc.lastIndexOf("-")) }} {{ item.scbjsj | date('yyyy-MM-dd HH:mm:ss') }}
      </div>
      <div
        v-if="!showHjVideo"
        class="hjlb-container-item-sj"
      >
        {{ item.azwz }} {{ item.bjmc.substring(0,item.bjmc.lastIndexOf("-")) }} <br>
        {{ item.scbjsj | date('yyyy-MM-dd HH:mm:ss') }}
      </div>
      <div
        v-if="item.slxxMxList && item.slxxMxList.length > 0 && item.slxxMxList[0].fhfqMc"
        class="hjlb-sb-tj"
      >
        <div
          v-for="(bjsj,index2) in bjsblxTj"
          :key="index2"
        >
          <span
            v-if="bjsj.num > 0"
          >{{ bjsj.num }}个
            <span style="color:#FF5722;">{{ bjsj.sblxmc }}，</span>
          </span>
        </div>
        发生报警
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HjlbTJ',
  components: {

  },
  props: {
    hjxxList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    slxxMxList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    bjztPageList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    slxxId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

      bjsblxTj: [
        { sblxbm: 'HZ001', sblxmc: '烟感', num: 0 },
        { sblxbm: 'HZ002', sblxmc: '温感', num: 0 },
        { sblxbm: 'HZ006', sblxmc: '手报', num: 0 },
        { sblxbm: 'XFQ002', sblxmc: '消钮', num: 0 },
        { sblxbm: 'qt', sblxmc: '未定义设备', num: 0 }
      ]
    };
  },
  computed: {
    showHjVideo () {
      // 控制是否展示火警视频
      // 如果当前用户单位是清远康大、颐和等，则增加展示部件的安装位置
      const lwdw = this.$HMStoreService.getUserinfo() && this.$HMStoreService.getUserinfo().dept.deptCode;
      if (lwdw === '2c9d80938a6e461c018ab24f59316041' || lwdw === '2c9d80938a6e461c018ab24e007b6040' || lwdw === '2c9d80938a6e461c018ab0c975a65fa0') {
        return false;
      }
      return true;
    }
  },
  methods: {
    handlechangeHzSeleteId (item) {
      if (this.slxxId === item.id) {
        return;
      }
      this.$emit('handlechangeHzSeleteId', item.id);
    }
  },
  watch: {
    hjxxList (val) {
      console.log('hjxxList新的值', val);
    },
    slxxMxList (val) {
      console.log('slxxMxList新的值', val);
      if (val && val.length > 0) {
          // 重置
          this.bjsblxTj.forEach(item => {
            item.num = 0;
          });
          // 统计报警设备类型数
          val.forEach(item => {
            var num = 0;
            this.bjsblxTj.forEach(vo => {
              if (item.sslx === vo.sblxbm) {
                vo.num++;
                num++;
              }
            });
          if (num === 0) {
            this.bjsblxTj[4].num++;
          }
      });
      }
    }
  },
  mounted () {
  },
  beforeDestroy () {
  }
};
</script>

<style scoped lang="scss">
.hjlb-container{
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.hjlb-container::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.hjlb-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.hjlb-container:hover::-webkit-scrollbar-thumb {
  height: 20px;
  background: #09369A;
}

.hjlb-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.hjlb-container-item{
  height: 103px;;
  // background: url('/monitor/images/hj/火警列表_矩形选中.svg') no-repeat center center;
  // background-size: contain; // 或者选择其他的适应方式，如cover

  display: flex;
  flex-direction: column; // 让子元素垂直排列
  justify-content: center; // 垂直居中
  align-items: flex-start; //

  margin-top: 8px;
  position: relative; // 添加相对定位
  background-image: url('/monitor/images/hj/火警列表_矩形.svg') ;
  background-size: 125% 100%;

  &_bgxz{
    background: url('/monitor/images/hj/火警列表_矩形选中.svg');
    background-size: 125% 100%;
  }
}

.hjlb-container-item-text{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;

  display: flex;
  align-items: center;

}
.hjlb-container-item-sj{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #00CDFF;
  letter-spacing: 0;
  margin-left: 42px;
  margin-top: 8px;
}
.hjlb-container-item-img{
  width: 13px;
  height: 13px;
  margin-left: 20px;
  margin-right: 10px;
}
.hjlb-container-item-shoujing{
  width: 64px;
  height: 26px;

  position: absolute;
  right: 20px;
  top: 50%; // 可以调整这个值，使其垂直居中或根据需要放置
  transform: translateY(-50%); // 如果需要垂直居中
}
.hjlb-sb-tj{
  display: flex;
  width: 90%;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
  margin-left: 42px;
}

</style>
