<!--
 * @Author: zry
 * @Date: 2024-01-23 16:55:53
 * @LastEditors: zry
 * @LastEditTime: 2024-02-05 11:32:08
 * @Description: 部件历史火警报警/误报热力图 按时间段统计
-->
<template>
  <div class="container">
    <div
      ref="lszshj"
      style="width: 800px; height: 200px;"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { lshjService } from './LshjService.js';
export default {
  name: 'LshjChart',
  props: {
    groupBy: {
      type: String,
      default: 'me'
    },
    ssdw: {
      type: String,
      default: ''
    },
    gzsbh: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lszshjChart: null,
      windowWidth: 0,
      windowHeight: 0,
      hours: [
        '0:00-4:00',
        '4:00-8:00',
        '8:00-12:00',
        '12:00-16:00',
        '16:00-20:00',
        '20:00-24:00'
      ],
      days: ['07-01', '07-02', '07-03', '07-04', '07-05', '07-06', '07-07'],
      listData: [
        [0, 0, 53],
        [1, 0, 39],
        [2, 0, 19],
        [3, 0, 31],
        [4, 0, 9],
        [5, 0, 9],

        [0, 1, 7],
        [1, 1, 3],
        [2, 1, 12],
        [3, 1, 38],
        [4, 1, 9],
        [5, 1, 1],

        [0, 2, 26],
        [1, 2, 43],
        [2, 2, 17],
        [3, 2, 52],
        [4, 2, 3],
        [5, 2, 9],

        [0, 3, 34],
        [1, 3, 15],
        [2, 3, 18],
        [3, 3, 11],
        [4, 3, 2],
        [5, 3, 2],

        [0, 4, 12],
        [1, 4, 0],
        [2, 4, 4],
        [3, 4, 2],
        [4, 4, 2],
        [5, 4, 1],

        [0, 5, 3],
        [1, 5, 7],
        [2, 5, 2],
        [3, 5, 6],
        [4, 5, 1],
        [5, 5, 0],

        [0, 6, 4],
        [1, 6, 1],
        [2, 6, 1],
        [3, 6, 7],
        [4, 6, 0],
        [5, 6, 0]

      ],
      minValue: 0,
      maxValue: 50

    };
  },
  methods: {
    queryRl () {
      const _this = this;
      const params = {
        ssdw: _this.ssdw,
        gzsbh: _this.gzsbh,
        gb: _this.groupBy // me: 此部件, all: 同楼层部件
      };
      lshjService.queryRl(params).then(res => {
        if (res.data) {
          const data = res.data;
          // console.log('热力分布data', data);
          //   _this.topData = data.data;
          _this.days = data.days;
          _this.listData = data.listData;
          if (data.listData.length === 0) {
            _this.maxValue = 1;
          } else {
            _this.maxValue = data.maxValue;
          }
          _this.initEchart();
        }
      }).catch(err => {
        console.log('热力分布err', err);
        _this.initEchart();
      });
    },
    updateWindowSize () {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    //   console.log('windowWidth', this.windowWidth);
    },
    initEchart () {
      const _this = this;
      const hours = _this.hours;
      const days = _this.days;
      const data = _this.listData;
      const minValue = _this.minValue;
      const maxValue = _this.maxValue;

      this.lszshjChart = echarts.init(this.$refs.lszshj);
      const option = {
        tooltip: {
          position: 'right',
          confine: true
        },
        // title: {
        //   text: '火警/误报热力图',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: 16
        //   }
        // },
        animation: true,
        grid: {
          width: 'auto', // 宽度
          height: 'auto', // 高度auto
          top: '5%',
          left: '15%',
          bottom: '10%'
        },
        xAxis: {
          type: 'category',
          data: hours,
          splitArea: {
            show: true
          },
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#c4c4c4'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: days,
          splitArea: {
            show: true
          },
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            show: false
          }
        },
        visualMap: {
          show: false,
          min: minValue,
          max: maxValue,
          calculable: true,
          orient: 'vertical',
          left: '20px',
          //   bottom: '20%',
          width: 'auto',
          // right: '50px',
          top: '10%',
          bottom: '10%',
          color: ['#FF7A30', '#FFF7EE ']
          // color: ['#0d59b7', '#bee8ff']
        },
        series: [
          {
            name: '',
            type: 'heatmap',
            data: data,
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      this.lszshjChart.setOption(option);
    },
    resizeChart () {
      if (this.lszshjChart) {
        this.lszshjChart.resize();
      }
    }
  },
  watch: {
    groupBy: {
      handler (val, oldVal) {
        const _this = this;
        console.log('groupBy', val);
        if (this.lszshjChart) {
          _this.queryRl();
        }
      },
      immediate: true
    }
  },
  created () {
    // 请求数据
  },
  mounted () {
    const _this = this;
    _this.queryRl();
    window.addEventListener('resize', _this.resizeChart);
    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeChart);
    window.removeEventListener('resize', this.updateWindowSize);
  }
};
</script>

<style>
.container {
    /* width: 100%;
    height: 100%; */
}
::v-deep .uni-tag {
    width: 200px;
}
</style>
