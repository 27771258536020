<template>
  <div class="firewarn_plan_sd">
    <div
      class="monitor_schart-header u-font-PFSC"
      style="position: relative;"
    >
      <div style="position: absolute; top: -38px;right: 0px; ">
        <hm-tree-select
          v-model="fd.treeValue"
          class="c-form__hm-treeselect c-tree-select-wrap"
          instance-id="cTreeSelect"
          placeholder="请选择楼层"
          :append-to-body="true"
          :multiple="false"
          :clearable="false"
          :options="lcList"
          style="width: 200px;background: #09193f"
          @input="getChildren"
        />
      </div>
    </div>
    <div class="dev-c-dCard__body">
      <div class="wzdx c-pmt">
        <div class="c-pmt__title">
          <div
            class="c-pmt__subtitle"
            :class="{'active': activeIndex==='1'}"
            @click="handleSelect('1')"
          >
            楼层平面图
            <!-- <span
              v-if="cadTypeFlag[1-1]"
              class="dev-bj-1"
            >1</span>
            <span
              v-else
              class="dev-bj-0"
            >0</span> -->
          </div>

          <div
            class="c-pmt__subtitle"
            :class="{'active': activeIndex==='5'}"
            @click="handleSelect('5')"
          >
            疏散指引图
            <!-- <span
              v-if="cadTypeFlag[5-1]"
              class="dev-bj-1"
            >1</span>
            <span
              v-else
              class="dev-bj-0"
            >0</span> -->
          </div>
        </div>

        <DxfViewer
          ref="viewer"
          :a-jzw-cad="aJzwCad"
          :show-bjzt="activeIndex!=='5'"
          :animation-xfsb-id="bjxx.id"
          :fonts="fonts"
          style="height: 315px"
          @dxf-loaded="_OnLoaded"
          @dxf-cleared="_OnCleared"
          @dxf-message="_OnMessage"
          @updateXfsb="updateXfsb"
        />
        <!-- <el-row class="wzdx-el-row"> -->
        <!-- <el-col
            :span="24"
            style="height: 330px;"
          >
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">
                楼层平面图
                <span
                  v-if="cadTypeFlag[1-1]"
                  class="dev-bj-1"
                >1</span>
                <span
                  v-else
                  class="dev-bj-0"
                >0</span>
              </el-menu-item>
              <el-menu-item index="5">
                疏散指引图
                <span
                  v-if="cadTypeFlag[5-1]"
                  class="dev-bj-1"
                >1</span>
                <span
                  v-else
                  class="dev-bj-0"
                >0</span>
              </el-menu-item>
            </el-menu>
          </el-col> -->
        <!--          <el-col-->
        <!--            :span="5"-->
        <!--            class="wzdx-el-col"-->
        <!--          >-->
        <!--            &lt;!&ndash; <LayersList-->
        <!--              :layers="layers"-->
        <!--              @toggleLayer="_OnToggleLayer"-->
        <!--              @toggleAll="_OnToggleAll"-->
        <!--            /> &ndash;&gt;-->
        <!--            <XfsbList-->
        <!--              v-show="activeIndex!=='5'"-->
        <!--              :xfsbs="xfsbs"-->
        <!--              @toggleXfsb="_OnToggleXfsb"-->
        <!--              @toggleAllXfsb="_OnToggleAllXfsb"-->
        <!--            />-->
        <!--          </el-col>-->
        <!-- </el-row> -->
      </div>
    </div>
  </div>
</template>

<script>
// import SubTitle from '../components/SubTitle.vue';
import DxfViewer from './DxfViewer.vue';
// import LayersList from '../../cad/wzdx/LayersList.vue';
// import XfsbList from './XfsbList.vue';
import { DxfViewer as _DxfViewer } from 'dxf-viewer';
import Vue from 'vue';
// import mainFont from '@/features/cad/fonts/Roboto-LightItalic.ttf';
// import aux1Font from '@/features/cad/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf';
// import aux2Font from '@/features/cad/fonts/HanaMinA.ttf';
// import aux3Font from '@/features/cad/fonts/NanumGothic-Regular.ttf';
import { warnService } from './fireWarn.service';
import EventBus from '../../../event-bus';
export default {
  name: 'FloorPlanImg',
  components: {
    // SubTitle,
    DxfViewer
    // LayersList
    // XfsbList
  },
  props: {
    bjxx: {
      type: Object,
      required: true,
      default () {
        return {
          azwz: null,
          jzid: null,
          jzlc: null
        };
      }
    }
  },
  data () {
    return {
      activeIndex: '1', // 当前选中的cad类型
      cadTypeFlag: [false, false, false, false, false], // cad类型标志
      aJzwCad: null, // 当前选中的cad
      sfczJzwFla: false,
      selectJzwId: null,
      jzwList: [],
      lcList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      jzwCadList: [],
      /* 树组件绑定的参数 Start */
      fd: {
        treeValue: null
      },
      layers: null,
      xfsbs: null
    };
  },
  methods: {
    handleNodeClick (data) {
      this.jzwCadList = data.jzwCadList;
      // 重置cad类型
      const cadTypeFlag = [false, false, false, false, false];
      this.jzwCadList.forEach(aCad => {
        cadTypeFlag[aCad.cadType - 1] = true;
      });
      this.cadTypeFlag = cadTypeFlag;
      this.activeIndex = cadTypeFlag.findIndex(a => a === true) + 1 + '';
      this.aJzwCad = this.jzwCadList.find(aCad => aCad.cadType === this.activeIndex);
      //  console.log('handleNodeClick', this.jzwCadList, this.cadTypeFlag, this.activeIndex, this.aJzwCad);

      // 获取文件下载路径
      // this.handlePreview(data.url);
    },

    handleSelect (_index) {
      // 避免重复加载问题
      console.log('handleSelectbefore');
      if (_index === this.activeIndex) return;
      this.activeIndex = _index;
      this.aJzwCad = this.jzwCadList.find(n => n.cadType === _index);
      console.log('handleSelect', _index, this.activeIndex, this.aJzwCad, this.activeIndex === '5');
    },
    /**
     * 通过火灾受理信息对应建筑物列表信息
     */
    getJzwBySsdw (_slId) {
      this.loading = true;
      warnService.getJzwBySsdw(_slId)
        .then(rsp => {
          this.jzwList = rsp.data;
          if (this.jzwList.length > 1) {
            // 开启多个建筑物下拉选择按钮
            this.sfczJzwFla = true;
          }
          this.init(this.jzwList[0].jzwId);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    init (_id, lcid) {
      this.loading = true;
      warnService.getCadList(_id)
        .then(rsp => {
          this.lcList = rsp.data;
          this.dialogParams = { id: this.lcList[0].id, mc: this.lcList[0].label };
          if (this.lcList && this.lcList.length > 0) {
            this.lcList.forEach(element => {
              if (element.children && element.children.length > 0) {
                element.children.forEach(node => {
                  if (node.id === `${lcid}`) {
                    this.fd.treeValue = node.id;
                    this.handleNodeClick(node);
                  }
                });
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getChildren () {
      this.lcList[0].children.forEach(e => {
        if (e.id === this.fd.treeValue) {
          this.handleNodeClick(e);
        }
      });
    },
    _OnLoaded () {
      const layers = this.$refs.viewer.GetViewer().GetLayers();
      layers.forEach(lyr => Vue.set(lyr, 'isVisible', true));
      this.layers = layers;
    },
    _OnCleared () {
      this.layers = null;
    },
    _OnToggleLayer (layer, newState) {
      layer.isVisible = newState;
      this.$refs.viewer.GetViewer().ShowLayer(layer.name, newState);
    },
    _OnToggleAll (newState) {
      if (this.layers) {
        for (const layer of this.layers) {
          if (layer.isVisible !== newState) {
            this._OnToggleLayer(layer, newState);
          }
        }
      }
    },
    updateXfsb (xfsbs) {
      xfsbs.forEach(el => { el.isVisible = true; });
      this.xfsbs = xfsbs;
    },
    _OnToggleXfsb (xfsb, newState) {
      xfsb.isVisible = newState;
      this.$refs.viewer.GetDxfControls().lookXfsb(xfsb);
      this.$refs.viewer.GetDxfControls().showXfsb(xfsb, newState);
    },
    _OnToggleAllXfsb (newState) {
      this.xfsbs.forEach(el => { el.isVisible = newState; });
      this.$refs.viewer.GetDxfControls().showAllXfsb(newState);
    },
    _OnMessage (e) {
      let type = 'info';
      switch (e.detail.level) {
        case _DxfViewer.MessageLevel.WARN:
          type = 'warning';
          break;
        case _DxfViewer.MessageLevel.ERROR:
          type = 'error';
          break;
      }
      this.$message({
        type: type,
        showClose: true,
        message: e.detail.message
      });
    }
  },
  watch: {
    bjxx: function (nVal, oVal) {
      console.log('floorPlanImg', this.bjxx);
      if (!nVal) {
        return;
      }
      if (!oVal) {
        this.init(nVal.jzid, nVal.jzlc);
        return;
      }
      if (nVal && oVal && nVal.jzid !== oVal.jzid) {
        console.log('部件信息变化', nVal, oVal);
        this.init(nVal.jzid, nVal.jzlc);
      }
    }
  },
  mounted () {
    const _this = this;
    EventBus.$on('hj_qrhj', function (data) {
      console.log('火警确认展示疏散图');
      _this.handleSelect('5');
    });
  },
  created () {
    this.fonts = ['HanaMinA', 'NotoSansDisplay-SemiCondensedLightItalic', 'Roboto-LightItalic', 'NanumGothic'];
    // if (this.slxxId) {
    //   this.getJzwBySsdw(this.slxxId);
    // }
  }
};
</script>

<style scoped lang="scss">
@import '../../virtual/chart/chart.scss';

.monitor_schart-header{
  display: flex;
  margin: 0 0 10px;
}

.firewarn_plan_sd {
  box-sizing: border-box;
  #width: 819px;
  #height: 548px;
}

.wzdx {
  width: 100%;
  height: 335px;
}

.wzdx-el-row {
  height: 100%;
  background: #fff;
}

.wzdx-lcWapper {
  width: 100%;
  background: #fff;
}

.wzdx-el-col {
  height: 100%;
  overflow: auto;
}

.dev-bj{
  border-radius: 30% ;
  font-size: 16px;
  color: rgb(255, 255, 255);
  //background-color: #73bbf7;

  &-1{
    color: #fcffff;
    background-color: #67c23a;
  }

  &-0{
    color: #f77c73;
    background-color: #fbe3e1;
  }
}

.c-pmt{
  box-sizing: border-box;
  height: 386px;
  padding: 10px;
  background: url('/monitor/images/hj/pmt.png') no-repeat center center;
  background-size: 100% 100%;

  &__title{
    color: #fff;
    text-align: center;
  }

  &__subtitle{
    display: inline-block;
    padding: 12px;
    margin-top: 12px;
    font-size: 14px;
    user-select: none;
    &.active{
      color: #00D0FF;
    }
  }
}

.c-form__hm-treeselect {
  ::v-deep.vue-treeselect__control {
    border-color: #004088 !important;
    background-color: #09193f !important;
  }
  ::v-deep.vue-treeselect__single-value{
    color: whitesmoke;
  }
}
</style>
