<!--
 * @Author: zxf
 * @Date: 2023-10-17 21:00:07
 * @LastEditors: zxf
 * @LastEditTime: 2023-10-17 22:27:38
 * @Description: 确认事项
-->
<template>
  <div class="c-cfsx">
    <!-- <div
      style="font-size: 14px;"
      @click="controlMainStatus='auto'"
    >
      auto
    </div>
    <div
      style="font-size: 14px;"
      @click="done=1"
    >
      reset
    </div>
    <div
      style="font-size: 14px;"
      @click="handleToNext()"
    >
      nextPart
    </div> -->
    <div
      class="c-cfsx__pw c-cfsx__pw--1"
      :class="{
        'c-cfsx__pw--pass': controlMainStatus==='auto',
        'c-cfsx__pw--done': controlMainStatus==='auto' && done===1,
        'c-cfsx__pw--bug': controlMainStatus==='Manual'
      }"
    >
      <div class="c-cfsx__part c-cfsx__part--1">
        <div class="c-cfsx__title">
          1. 确认自动控制器全部打开
        </div>
        <div class="c-cfsx__sx1" />
      </div>
      <div class="c-cfsx__line c-cfsx__line--1" />
      <div class="c-cfsxtips__p c-cfsxtips__p--1">
        控制器自动状态
      </div>
      <div class="c-cfsxtips__pe c-cfsxtips__p--1-err">
        控制器手动状态！
      </div>
    </div>
    <div
      class="c-cfsx__pw c-cfsx__pw--2"
      :class="{
        'c-cfsx__pw--pass': done>=2,
        'c-cfsx__pw--done': done===2
      }"
    >
      <div class="c-cfsx__part c-cfsx__part--2">
        <div class="c-cfsx__title">
          2. 检查周边灭火设施
        </div>
        <div class="c-cfsx__sx1" />
      </div>
      <div class="c-cfsx__line c-cfsx__line--2" />
      <div class="c-cfsxtips__p c-cfsxtips__p--2">
        周边设备已启动
      </div>
    </div>
    <div
      class="c-cfsx__pw c-cfsx__pw--4"
      :class="{
        'c-cfsx__pw--pass': done>=4,
        'c-cfsx__pw--done': done===4
      }"
    >
      <div class="c-cfsx__part c-cfsx__part--4">
        <div class="c-cfsx__title">
          4. 向消防部门报警
        </div>
        <div class="c-cfsx__sx1" />
      </div>
      <div class="c-cfsx__line c-cfsx__line--4" />
      <div class="c-cfsxtips__p c-cfsxtips__p--4">
        报警信息已发送
      </div>
    </div>
    <div
      class="c-cfsx__pw c-cfsx__pw--3"
      :class="{
        'c-cfsx__pw--pass': done>=3,
        'c-cfsx__pw--done': done===3
      }"
    >
      <div class="c-cfsx__part c-cfsx__part--3">
        <div class="c-cfsx__title">
          3. 广播疏散大楼人群
        </div>
        <div class="c-cfsx__sx1" />
      </div>
      <div class="c-cfsxtips__p c-cfsxtips__p--3">
        广播已打开
      </div>
    </div>

    <!-- tips -->
    <div class="c-cfsxtips" />
  </div>
</template>

<script>
export default {
  name: 'ComfirmPart',
  props: {
    controlMainStatus: {
      type: String,
      default: 'Manual'
      // Manual 手动，auto 自动
    },
    xxqr: {
      type: String,
      default: null
    },
    done: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      nextPart: null
    };
  },
  methods: {
    handleToNext () {
      const _this = this;
      this.done = 2;
      this.nextPart = setInterval(() => {
        _this.done++;
        if (_this.done >= 6) {
          clearInterval(_this.nextPart);
        }
      }, 1000 * 2);
    }
  },
  watch: {
    controlMainStatus (newVal, oldVal) {
      // if (newVal === 'auto') {
      //   this.handleToNext();
      // }
    },
    xxqr (newVal, oldVal) {
      console.log('xxqrnewVal', newVal);
      if (newVal === '2' || newVal === 2) {
        this.handleToNext();
      }
    }
  },
  beforeDestroy () {
    if (this.nextPart) {
      clearInterval(this.nextPart);
    }
  }
};
</script>

<style lang="scss">
.c-cfsx{
  font-size: 0;
  color: #fff;

  &__pw{
    display: inline-block;
    position: relative;
    width: 50%;

    &--1,&--2{
      margin-bottom: 24px;
    }

    &--2,&--3{
      text-align: right;
    }

  }

  &__part{
    display: inline-block;
    text-align: center;
  }

  &__title{
    display: inline-block;
    position: relative;
    z-index: 12;
    width: 186px;
    height: 38px;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 38px;
    text-align: center;
    background: url('/monitor/images/comfirmPart/title.png') no-repeat center center;
  }

  &__sx1{
    position: relative;
    z-index: 12;
    height: 100px;
    background: url('/monitor/images/comfirmPart/p1.png') no-repeat center center;
  }

  &__sx2{
    position: relative;
    z-index: 12;
    height: 100px;
    background: url('/monitor/images/comfirmPart/p2.png') no-repeat center center;
  }

  &__sx3{
    position: relative;
    z-index: 12;
    height: 100px;
    background: url('/monitor/images/comfirmPart/p3.png') no-repeat center center;
  }

  &__sx4{
    position: relative;
    z-index: 12;
    height: 100px;
    background: url('/monitor/images/comfirmPart/p4.png') no-repeat center center;
  }

  &__line{
    display: inline-block;
    position: absolute;
    z-index: 10;
    width: 200px;
    height: 12px;
    background: url('/monitor/images/comfirmPart/line-err.png') no-repeat center center;

    &--1{
      top: 106px;
      left: 138px;
    }

    &--2{
      top: 154px;
      left: 100px;
      width: 90px;
      transform: rotate(90deg);
    }

    &--4{
      top: 106px;
      left: 138px;
      transform: rotate(180deg);
    }
  }
}

.c-cfsx__pw--pass{
  .c-cfsx__line{
    background: url('/monitor/images/comfirmPart/line.png') no-repeat center center;

    &.c-cfsx__line--2{
      top: 143px;
    }
  }
}

.c-cfsx__pw--done .c-cfsxtips__p{
  visibility: visible;
}

.c-cfsx__pw--bug .c-cfsxtips__pe{
  visibility: visible;
}

.c-cfsxtips{
  &__p{
    display: inline-block;
    position: absolute;
    top: 60px;
    left: 114px;
    visibility: hidden;
    width: 101px;
    height: 32px;
    font-size: 12px;
    line-height: 30px;
    color: #00F0FF;
    text-align: center;
    background: url('/monitor/images/comfirmPart/tip.png') no-repeat center center;

    &--2,&--3{
      left: 0;
    }
  }

  &__pe{
    display: inline-block;
    position: absolute;
    top: 60px;
    left: 114px;
    visibility: hidden;
    width: 131px;
    height: 32px;
    font-size: 12px;
    line-height: 30px;
    color: #FF5E1D;
    text-align: center;
    background: url('/monitor/images/comfirmPart/tip-err.png') no-repeat center center;
  }
}
</style>
