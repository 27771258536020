var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cfsx"},[_c('div',{staticClass:"c-cfsx__pw c-cfsx__pw--1",class:{
      'c-cfsx__pw--pass': _vm.controlMainStatus==='auto',
      'c-cfsx__pw--done': _vm.controlMainStatus==='auto' && _vm.done===1,
      'c-cfsx__pw--bug': _vm.controlMainStatus==='Manual'
    }},[_vm._m(0),_c('div',{staticClass:"c-cfsx__line c-cfsx__line--1"}),_c('div',{staticClass:"c-cfsxtips__p c-cfsxtips__p--1"},[_vm._v(" 控制器自动状态 ")]),_c('div',{staticClass:"c-cfsxtips__pe c-cfsxtips__p--1-err"},[_vm._v(" 控制器手动状态！ ")])]),_c('div',{staticClass:"c-cfsx__pw c-cfsx__pw--2",class:{
      'c-cfsx__pw--pass': _vm.done>=2,
      'c-cfsx__pw--done': _vm.done===2
    }},[_vm._m(1),_c('div',{staticClass:"c-cfsx__line c-cfsx__line--2"}),_c('div',{staticClass:"c-cfsxtips__p c-cfsxtips__p--2"},[_vm._v(" 周边设备已启动 ")])]),_c('div',{staticClass:"c-cfsx__pw c-cfsx__pw--4",class:{
      'c-cfsx__pw--pass': _vm.done>=4,
      'c-cfsx__pw--done': _vm.done===4
    }},[_vm._m(2),_c('div',{staticClass:"c-cfsx__line c-cfsx__line--4"}),_c('div',{staticClass:"c-cfsxtips__p c-cfsxtips__p--4"},[_vm._v(" 报警信息已发送 ")])]),_c('div',{staticClass:"c-cfsx__pw c-cfsx__pw--3",class:{
      'c-cfsx__pw--pass': _vm.done>=3,
      'c-cfsx__pw--done': _vm.done===3
    }},[_vm._m(3),_c('div',{staticClass:"c-cfsxtips__p c-cfsxtips__p--3"},[_vm._v(" 广播已打开 ")])]),_c('div',{staticClass:"c-cfsxtips"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cfsx__part c-cfsx__part--1"},[_c('div',{staticClass:"c-cfsx__title"},[_vm._v(" 1. 确认自动控制器全部打开 ")]),_c('div',{staticClass:"c-cfsx__sx1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cfsx__part c-cfsx__part--2"},[_c('div',{staticClass:"c-cfsx__title"},[_vm._v(" 2. 检查周边灭火设施 ")]),_c('div',{staticClass:"c-cfsx__sx1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cfsx__part c-cfsx__part--4"},[_c('div',{staticClass:"c-cfsx__title"},[_vm._v(" 4. 向消防部门报警 ")]),_c('div',{staticClass:"c-cfsx__sx1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cfsx__part c-cfsx__part--3"},[_c('div',{staticClass:"c-cfsx__title"},[_vm._v(" 3. 广播疏散大楼人群 ")]),_c('div',{staticClass:"c-cfsx__sx1"})])
}]

export { render, staticRenderFns }