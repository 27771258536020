<template>
  <div class="video-box">
    <video
      ref="videoPlayer"
      :poster="vimage"
      :controls="settingControl"
      autoplay
      muted
      style="max-height: 450px"
    />
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  name: 'Home',
  components: {},
  props: {
    videoUrl: {
      type: String,
      default: null,
      required: true
    },
    vimage: {
      type: String,
      default: '/assets/video.png',
      required: true
    },
    settingControl: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      hls: null
    };
  },
  methods: {
    play () {
      if (Hls.isSupported()) {
        console.log('videoUrl', this.videoUrl);
        const video = this.$refs.videoPlayer;
        const hls = new Hls();
        hls.loadSource(this.videoUrl);
        hls.attachMedia(video);

        const _this = this;
        let MANIFEST_LOADED = false;
        let MANIFEST_OVERTIME = false;
        hls.on(Hls.Events.MANIFEST_LOADED, function () {
          // 触发重新加载
          if (!MANIFEST_LOADED) {
            console.log('成功解析清单，触发一次重加载');
            MANIFEST_LOADED = true;
            // 设置延时播放，解决画面卡主问题
            video.pause();

            // 如果1秒钟后发现视频时长大于3秒，则直接播放
            setTimeout(() => {
              var duration = _this.hls.media.duration;
              // 向下取整 ，如果小于2则取0，大于2则 -2
              var seekTime = Math.floor(duration) <= 2 ? 1 : Math.floor(duration) - 2;
              if (duration >= 2 && !MANIFEST_OVERTIME) {
                video.currentTime = seekTime;
                video.play();
                MANIFEST_OVERTIME = true;
                console.log('触发1');
              }
            }, 1000);
            // 如果1秒钟后发现视频时长小于3秒，则延迟更多时间播放
            setTimeout(() => {
              var duration = _this.hls.media.duration;
              // 向下取整 ，如果小于2则取0，大于2则 -2
              var seekTime = Math.floor(duration) <= 2 ? 1 : Math.floor(duration) - 2;
              if (!MANIFEST_OVERTIME) {
                video.currentTime = seekTime;
                video.play();
                MANIFEST_OVERTIME = true;
                console.log('触发2');
              }
            }, 3000);
          }
        });

        hls.loadSource(this.videoUrl);
        hls.attachMedia(video);

        this.hls = hls;
      }
    }
  },
  watch: {
    videoUrl () {
      // 如果this.hls正在播放，则断开
      if (this.hls) {
        this.hls.destroy();
      }
      this.play();
    }
  },
  mounted () {
    this.play();
  },
  beforeDestroy () {
    if (this.hls) {
      this.hls.destroy();
    }
  },
  activated () {
  },
  deactivated () {

  }
};
</script>
<style>
.video-box {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

video::-webkit-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-current-time-display {
  display: none;
}

video::-webkit-media-controls-timeline {
  display: none;
}
</style>
